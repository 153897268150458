<template>
  <div class="modal topup-modal sale-skin-modal">
    <div class="overlay" @click="$emit('closeSaleSkinModal')"></div>
    <div class="wrapper">
      <div class="container">
        <form class="form">
          <div class="form-wrapper">
            <img
              class="close"
              @click="$emit('closeSaleSkinModal')"
              src="./../images/close.svg"
            />
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="desc">
                      <b>SELL YOUR SKINS</b>
                    </div>
                  </div>
                  <div class="topup-form">
                    <div class="input-container">
                      <input
                        type="text"
                        placeholder="Enter skin name"
                        v-model="title"
                      />
                    </div>
                    <div class="input-container">
                      <input
                        type="text"
                        placeholder="Desired price"
                        v-model="price"
                      />
                    </div>
                    <div class="input-container">
                      <select v-model="quality">
                        <option value="" selected disabled>Quality</option>
                        <option
                          v-for="(item, i) in qualityOptions"
                          :value="item"
                          :key="i"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="input-container">
                      <input
                        type="text"
                        placeholder="Steam inventory link to skin"
                        v-model="item_url"
                      />
                    </div>
                    <div class="buttons">
                      <div class="button" @click="$emit('closeSaleSkinModal')">
                        <span>CANCEL</span>
                      </div>
                      <div class="button red" @click="create()">
                        <span>Send</span>
                      </div>
                    </div>
                    <transition name="fade">
                      <div v-if="$parent.error" class="error-desc desc red">
                        {{ $parent.error }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaleSkinModal",
  props: [],
  components: {},
  data: function () {
    return {
      title: "",
      price: "",
      quality: "",
      item_url: "",
      qualityOptions: [
        "Well-Worn",
        "Field-Tested",
        "Minimal Wear",
        "Factory New",
      ],
    };
  },
  methods: {
    closeSaleSkinModal() {
      this.$emit("closeSaleSkinModal");
    },
    create() {
      let data = {
        title: this.title,
        price: this.price,
        quality: this.quality,
        item_url: this.item_url,
      };
      this.$emit("createOffer", data);
    },
  },
};
</script>
