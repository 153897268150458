<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">Offer sent successfully.</div>
          <div class="desc">We will contact you by email within 48 hours</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "OfferSuccessPage",
  props: [],
  components: {},
  data: function () {
    return {};
  },
  methods: {},
};
</script>
