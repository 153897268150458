<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu
            :isAuth="isAuth"
            v-if="menuVisible"
            :cartContents="cartContents"
            :currencyCode="currencyCode"
            @changeCurrency="changeCurrency"
          />
        </transition>
        <div
          class="mob menu-icon"
          @click="toggleMenu"
          :class="{ active: menuVisible }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="header-container">
          <Header
            :appBalance="appBalance"
            :isAuth="isAuth"
            :searchedProductText="searchedProductText"
            @searchSubmit="searchSubmit"
            :cartContents="cartContents"
            :currencyCode="currencyCode"
            @changeCurrency="changeCurrency"
          />
        </div>
        <router-view
          :currencyCode="currencyCode"
          @setAppBalance="setAppBalance"
          :isAuth="isAuth"
          :productsFilteredBySearch="productsFilteredBySearch"
          @openOfferDetailsModal="openOfferDetailsModal"
          @formatDate="formatDate"
          :addToCartChosenItem="addToCartChosenItem"
          :currency="currency"
          @addToCart="addToCart"
          @goToProduct="goToProduct"
          @login="login"
          @forgotPass="forgotPass"
          @registration="registration"
          :productCategory="productCategory"
          @setProductCategory="setProductCategory"
        />
        <div class="footer-container">
          <Footer
            v-if="
              isAuth ||
              (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')
            "
          />
        </div>
        <transition name="fade">
          <Login
            v-if="signInModalIsVisible"
            @closeSignIn="closeSignIn"
            @openSignUp="openSignUp"
            @login="login"
            @forgotPass="forgotPass"
          />
        </transition>
        <transition name="fade">
          <Registration
            v-if="signUpModalIsVisible"
            @closeSignUp="closeSignUp"
            @openSignIn="openSignIn"
            @registration="registration"
          />
        </transition>
        <transition name="fade">
          <Cart @setError="setError" v-if="cartIsVisible" />
        </transition>
        <transition name="fade">
          <ProfileModal v-if="profileModalIsVisible" />
        </transition>
        <transition name="fade">
          <TopUpModal @setError="setError" v-if="topupModalIsVisible" />
        </transition>
        <div class="modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="title">About Cookies</div>
            <div class="desc">
              We employ cookies and similar technologies to enhance the
              functionality of our website, providing you with a seamless and
              personalized browsing experience. Some cookies are essential for
              basic functionality and are set automatically without requiring
              explicit consent.
              <br /><br />
              In addition to essential cookies, we may offer optional cookies
              and similar technologies that bring various benefits, further
              enhancing your interaction with our website. As these are
              non-essential, their usage depends on your preferences. For more
              information, please refer to our
              <a @click="goToPage('cookie')" class="desc"> Cookie Policy</a>.
            </div>
            <button class="button red" @click="acceptCookies">Accept</button>
            <button class="button" @click="declineCookies">Decline</button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <OfferDetailsModal
        v-if="offerDetailsModalIsVisible"
        @closeOfferDetailsModal="closeOfferDetailsModal"
        :orderToView="orderToView"
      />
    </transition>
    <transition name="fade">
      <SaleSkinModal
        v-if="saleSkinModalIsVisible"
        @closeSaleSkinModal="closeSaleSkinModal"
        @createOffer="createOffer"
      />
    </transition>
    <transition name="fade">
      <AcceptOfferModal v-if="acceptOfferModalIsVisible" @setError="setError" />
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
import { loadScriptsFromString } from "@/utils/load_scripts";
import Login from "./components/Login.vue";
import Registration from "./components/Registration.vue";
import Cart from "./components/Cart.vue";
import ProfileModal from "./components/ProfileModal.vue";
import TopUpModal from "./components/TopUp.vue";

import OfferDetailsModal from "./components/OfferDetailsModal.vue";
import SaleSkinModal from "./components/SaleSkinModal.vue";
import AcceptOfferModal from "./components/AcceptOfferModal.vue";

export default {
  name: "app",
  components: {
    Header,
    Menu,
    Footer,
    Registration,
    Login,
    Cart,
    ProfileModal,
    TopUpModal,
    OfferDetailsModal,
    SaleSkinModal,
    AcceptOfferModal,
  },
  data: function () {
    return {
      pageLoaded: false,
      menuVisible: false,
      nav: [
        {
          title: "HOME",
          link: "/",
        },
        {
          title: "C2 MARKET",
          link: "/product-list/all",
        },
      ],
      isAuth: false,
      error: "",
      successMessage: "",
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: "",
      newArrivals: [],
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: "0.00",
      textPageTitle: "",
      textPageHeader: "",
      textPageContent: "",
      productPageItem: "",
      similarProducts: "",
      imgDomain: "",
      textPageList: "",
      productCategory: "",
      hostname: "",
      footerRequisites: "",
      footerSupportEmail: "",
      footerCopyright: "",
      categoryOptions: [],
      currency: "",
      currencyCode: "",
      currencyOptions: "",
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      cartIsVisible: false,
      profileModalIsVisible: false,
      topupModalIsVisible: false,
      depositHistory: [],
      searchedProductText: "",
      productsFilteredBySearch: "",
      cookesModalIsVisible: false,
      socials: "",
      appBalance: "",
      paymentMethods: [],
      offerDetailsModalIsVisible: false,
      saleSkinModalIsVisible: false,
      acceptOfferModalIsVisible: false,
      offerHistory: [],
    };
  },
  watch: {
    currencyCode() {
      this.getNewArrivals();
      this.getSale();
      this.getRecommended();
      if (this.productPageItem) {
        this.getProductPageContent(this.productPageItem.id);
      }
      if (this.isAuth) {
        this.orderHistory = "";
        this.cartContents = "";
        this.totalSum = "";
        this.getOrderHistory();
        this.getDepositHistory();
        this.getCartContents();
        this.getBalance();
        this.getOfferHistory();
      }
      this.checkCurrency();
    },
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOfferHistory();
        this.getOrderHistory();
        this.getDepositHistory();
      }
      this.productCategory = this.$route.params.id;
    },
  },
  methods: {
    openAcceptOfferModal() {
      this.acceptOfferModalIsVisible = true;
    },
    closeAcceptOfferModal() {
      this.acceptOfferModalIsVisible = false;
      this.clearError();
      this.orderToView = {};
    },
    createOffer(data) {
      this.$http
        .post(process.env.VUE_APP_API + "offer/create", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            this.closeSaleSkinModal();
            this.$router.push({ path: "/offer-sent-succes" });
          } else {
            // this.error = res.data.message
            if (res.response.data.errors) {
              const firstErrors = {};
              for (const key in res.response.data.errors) {
                if (
                  res.response.data.errors.hasOwnProperty(key) &&
                  Array.isArray(res.response.data.errors[key]) &&
                  res.response.data.errors[key].length > 0
                ) {
                  firstErrors[key] = res.response.data.errors[key][0];
                  this.$emit("setError", firstErrors[key]);
                }
              }
            } else {
              this.$emit("setError", res.response.data.message);
            }
          }
        })
        .catch((res) => {
          if (res.response.data.errors) {
            const firstErrors = {};
            for (const key in res.response.data.errors) {
              if (
                res.response.data.errors.hasOwnProperty(key) &&
                Array.isArray(res.response.data.errors[key]) &&
                res.response.data.errors[key].length > 0
              ) {
                firstErrors[key] = res.response.data.errors[key][0];
                // this.$emit('setError', firstErrors[key]);
                this.error = firstErrors[key];
              }
            }
          } else {
            this.error = res.response.data.message;
          }
          // this.error = res.response.data.message;
        });
    },
    openOfferDetailsModal(item) {
      this.orderToView = item;
      this.offerDetailsModalIsVisible = true;
    },
    closeOfferDetailsModal() {
      this.offerDetailsModalIsVisible = false;
      this.orderToView = {};
      this.getOfferHistory();
    },
    openSaleSkinModal() {
      this.saleSkinModalIsVisible = true;
    },
    closeSaleSkinModal() {
      this.saleSkinModalIsVisible = false;
    },
    getBalance() {
      if (this.isAuth) {
        this.$http
          .get(
            process.env.VUE_APP_API +
              "user/profile?currency=" +
              this.currencyCode
          )
          .then((res) => {
            this.appBalance = res.data.balance;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.openSignIn();
            }
          });
      }
    },
    setAppBalance(balance) {
      this.appBalance = balance;
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    clearSearch() {
      this.searchedProductText = "";
    },
    searchSubmit(text) {
      this.searchedProductText = text;
      this.productsFilteredBySearch = text;
      if (!this.$route.fullPath.includes("product-list/search")) {
        this.$router.push({ path: "/product-list/search" });
      }
    },
    getCurrencies() {
      this.$http
        .get(process.env.VUE_APP_API + "currencies")
        .then((res) => {
          this.currencyOptions = res.data.payload;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency");
          } else {
            this.currencyCode = this.currencyOptions[0].code;
          }
          this.checkCurrency();
        })
        .catch(() => {});
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      if (
        this.currencyCode &&
        this.currencyOptions &&
        this.currencyOptions.length
      ) {
        this.currency = this.currencyOptions.find(
          (item) => item.code == this.currencyCode
        ).symbol;
      }
      this.getBalance();
    },
    setProductCategory(category) {
      this.productCategory = category;
      if (category) {
        this.$router.push({ path: "/product-list/" + category });
      }
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newCategories = res.data.payload.types;
          this.categoryOptions = newCategories;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getDomainName(hostName) {
      return hostName.substring(
        hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
      );
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    clearError() {
      this.error = "";
    },
    setError(error) {
      this.error = error;
    },
    submitLoginSteam() {
      window.location.replace(
        "https://api.marketofskins.com/auth/steam/redirect"
      );
    },

    getOfferHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "offer/history")
        .then((res) => {
          this.offerHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    login(data) {
      this.$http
        .post(process.env.VUE_APP_API + "login", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.isAuth = true;
            this.getOrderHistory();
            this.getDepositHistory();
            this.getCartContents();
            this.closeSignIn();
            this.getOfferHistory();
            this.$router.push({ path: "/profile" });
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    forgotPass(data) {
      let self = this;
      this.$http
        .post(process.env.VUE_APP_API + "user/reset-password", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = "Success";
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 2000);
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
        });
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = "Password confirmation failed!";
      } else {
        this.$http
          .post(process.env.VUE_APP_API + "register", data)
          .then((res) => {
            if (res.data.status == "OK") {
              this.clearError();
              self.isAuth = true;
              this.closeSignUp();
              self.$router.push({ path: "/profile" });
            } else {
              this.error = res.data.message;
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
          });
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + "logout").then(() => {
        if (this.$route.name != "Home") {
          this.$router.push({ path: "/" });
        }
        this.isAuth = false;
        this.$router.push({ path: "/" });
      });
    },
    getDepositHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "deposit/history")
        .then((res) => {
          this.depositHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "purchase/history")
        .then((res) => {
          this.orderHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, "0");
      }
      let hoursAndMinutes =
        padTo2Digits(objectDate.getHours()) +
        ":" +
        padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + " " + hoursAndMinutes;

      return format;
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        id: id,
        count: "1",
      };
      if (item.count > 1) {
        cartItem = {
          id: id,
          count: item.count - 1,
        };
      }
      this.$http
        .post(process.env.VUE_APP_API + "cart/add", cartItem)
        .then((res) => {
          self.cartContents = res.data.cart;
          this.getCartContents();
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        id: id,
      };

      this.$http
        .post(process.env.VUE_APP_API + "cart/remove", cartItem)
        .then((res) => {
          self.cartContents = res.data.cart;
          this.getCartContents();
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    addToCart(item, id) {
      if (!this.isAuth) {
        this.openSignIn();
      } else {
        let self = this;

        let cartItem = {
          id: id,
          count: 1,
        };

        for (var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == id) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              id: id,
              count: cartItemCount + 1,
            };
            break;
          }
        }

        this.$http
          .post(process.env.VUE_APP_API + "cart/add", cartItem)
          .then((res) => {
            this.getCartContents();
            self.addToCartChosenItem = cartItem.id;
            self.cartContents = res.data.cart;
            setTimeout(() => {
              self.addToCartChosenItem = "";
            }, 2000);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.openSignIn();
            }
          });
      }
    },
    getNewArrivals() {
      let addCurrency = "";
      addCurrency = "&currency=" + this.currencyCode;
      this.$http
        .get(process.env.VUE_APP_API + "items/list?new=true" + addCurrency)
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getRecommended() {
      let addCurrency = "";
      addCurrency = "?currency=" + this.currencyCode;
      this.$http
        .get(process.env.VUE_APP_API + "items/recommended" + addCurrency)
        .then((res) => {
          this.recommendedProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getSale() {
      let addCurrency = "";
      addCurrency = "&currency=" + this.currencyCode;
      this.$http
        .get(process.env.VUE_APP_API + "items/list?sale=true" + addCurrency)
        .then((res) => {
          this.saleProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getCartContents() {
      this.$http
        .get(
          process.env.VUE_APP_API +
            "cart/contents?currency=" +
            this.currencyCode
        )
        .then((res) => {
          this.cartContents = res.data.cart;

          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice =
                this.cartContents[i].count *
                parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    goToProduct(item) {
      this.$router.push({
        path: "/products/" + item.id + "?currency=" + this.currencyCode,
        params: { data: item.id },
      });
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = "";
      this.similarProducts = "";
      let addCurrency = "";
      if (!this.currencyCode) {
        this.currencyCode = localStorage.getItem("currency");
      }
      addCurrency = "&currency=" + this.currencyCode;
      this.$http
        .get(process.env.VUE_APP_API + "items/" + id + addCurrency)
        .then((res) => {
          this.productPageItem = res.data.payload;
          this.similarProducts = res.data.payload.similar;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    goToPage(item) {
      let newItem;
      if (item == "privacy") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("privacy")) {
            newItem = this.textPageList[i].id;
            break;
          }
        }
      } else if (item == "terms") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("terms")) {
            newItem = this.textPageList[i].id;
            break;
          }
        }
      } else if (item == "cookie") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("cookie")) {
            newItem = this.textPageList[i].id;
            break;
          }
        }
      } else {
        newItem = item;
      }
      this.$router.push({
        path: "/pages/" + newItem,
        params: { data: newItem },
      });
      this.getTextPageContent(newItem);
      this.closeSignUp();
    },
    getTextPageContent(id) {
      this.textPageTitle = "";
      this.textPageHeader = "";
      this.textPageContent = "";
      this.$http
        .get(process.env.VUE_APP_API + "static-pages/" + id)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getTextPages() {
      this.$http
        .get(process.env.VUE_APP_API + "static-pages")
        .then((res) => {
          this.textPageList = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    openTopup() {
      this.topupModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopup() {
      this.topupModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    openProfileModal() {
      this.profileModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeProfileModal() {
      this.profileModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    openCart() {
      this.cartIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeCart() {
      this.cartIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    openSignIn() {
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignIn() {
      this.signInModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    openSignUp() {
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignUp() {
      this.signUpModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    openRecover() {
      this.$router.push({ path: "/recover" });
    },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          this.paymentMethods = res.data.payload.payment_methods;
          this.footerRequisites = res.data.payload.requisites;
          this.footerSupportEmail = res.data.payload.support_email;
          this.footerCopyright = res.data.payload.copyright;
          const snippet = res.data.payload.google_tag;
          if (snippet) {
            loadScriptsFromString(snippet);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Tag Manager snippet:", error);
        });
    },
    getSocials() {
      this.$http
        .get(process.env.VUE_APP_API + "social-links")
        .then((res) => {
          this.socials = res.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted: function () {
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname);
    this.productCategory = this.$route.params.id;
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();

    this.getCurrencies();

    self.getGoogleTagManagerSnippet();

    self.$http
      .get(process.env.VUE_APP_API + "is-auth")
      .then((res) => {
        console.log("auth: " + res.data.authenticated);
        self.isAuth = res.data.authenticated;

        if (res.data.authenticated) {
          self.getOfferHistory();
          self.getOrderHistory();
          self.getDepositHistory();
          self.getCartContents();
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.openSignIn();
        }
      });
    this.getCategoryOptions();
    this.getSale();
    this.getRecommended();
    this.getNewArrivals();
    this.getTextPages();
    this.getSocials();
  },
  computed: {},
};
</script>
