<template>
  <div class="modal topup-modal offer-modal accept-offer-modal">
    <div class="overlay" @click="$parent.closeAcceptOfferModal"></div>
    <div class="wrapper">
      <img
        class="close"
        @click="$parent.closeAcceptOfferModal"
        src="./../assets/close.svg"
      />
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="bottom" v-if="step == 1">
                    <div class="cart-top">
                      <div class="desc">
                        <b>Contact information</b>
                      </div>
                    </div>

                    <div class="offer-wrapper topup-form">
                      <label class="input-container">
                        <input type="text" placeholder="Name" v-model="name" />
                      </label>
                      <label class="input-container">
                        <input
                          type="text"
                          placeholder="Surname"
                          v-model="surname"
                        />
                      </label>
                      <label class="input-container">
                        <input
                          type="email"
                          placeholder="E-mail address"
                          v-model="email"
                        />
                      </label>
                      <label class="input-container">
                        <input type="tel" placeholder="Phone" v-model="phone" />
                      </label>
                      <label class="input-container">
                        <select v-model="country">
                          <option value="" disabled selected>Country</option>
                          <option
                            v-for="(item, i) in countryOptions"
                            :value="item"
                            :key="i"
                          >
                            {{ item.title }}
                          </option>
                        </select>
                      </label>
                      <label class="input-container">
                        <input type="text" placeholder="City" v-model="city" />
                      </label>
                      <label class="input-container">
                        <input
                          type="text"
                          placeholder="Address"
                          v-model="address"
                        />
                      </label>
                      <label class="input-container">
                        <input
                          type="text"
                          placeholder="Post Code"
                          v-model="postCode"
                        />
                      </label>
                    </div>
                  </div>
                  <div class="bottom" v-if="step == 2">
                    <div class="cart-top">
                      <div class="desc">
                        <b>Contact information</b>
                      </div>
                    </div>

                    <div class="offer-wrapper topup-form">
                      <label class="input-container">
                        <input
                          type="text"
                          pattern="\d*"
                          maxlength="19"
                          v-model="card_number"
                          placeholder="Card number"
                        />
                      </label>
                      <label class="input-container">
                        <input
                          type="text"
                          v-model="card_holder_name"
                          placeholder="Card holder name"
                        />
                      </label>
                      <div class="input-container">
                        <input
                          type="text"
                          pattern="\d*"
                          maxlength="2"
                          v-model="expire_month"
                          placeholder="Expiration date month"
                        />
                      </div>
                      <div class="input-container">
                        <input
                          type="text"
                          pattern="\d*"
                          maxlength="4"
                          v-model="expire_year"
                          placeholder="Expiration date year"
                        />
                      </div>
                      <div class="cta-container">
                        <div class="chekbox-container">
                          <label class="chekbox-label">
                            <div class="chekbox-label-wrapper">
                              <input
                                type="checkbox"
                                name="terms"
                                v-model="terms"
                              />
                              <div class="checkbox"></div>
                              <span class="title">I agree with </span>
                              <a @click="goToPrivacy" class="title">
                                privacy policy</a
                              >
                              <span class="title"> and </span>
                              <a @click="goToTerms" class="title"
                                >terms and conditions</a
                              >
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <transition name="slide">
                    <div v-if="$parent.error" class="error-desc desc red">
                      {{ $parent.error }}
                    </div>
                  </transition>

                  <div class="buttons-special">
                    <button
                      v-if="step == 1"
                      class="button"
                      @click="$parent.closeAcceptOfferModal"
                    >
                      Back
                    </button>
                    <button
                      v-if="step == 1"
                      :class="[
                        'button red',
                        { disabled: !requiredFieldsAreFilled2 },
                      ]"
                      @click="step = 2"
                    >
                      <span>Next</span>
                    </button>

                    <button v-if="step == 2" class="button" @click="step = 1">
                      Back
                    </button>

                    <button
                      v-if="step == 2"
                      :class="[
                        'button red',
                        { disabled: !requiredFieldsAreFilled },
                      ]"
                      @click="confirm"
                    >
                      <span>Confirm</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptOfferModal",
  props: [],
  components: {},
  data: function () {
    return {
      step: 1,
      card_holder_name: "",
      card_number: "",
      expire_month: "",
      expire_year: "",
      email: "",
      name: "",
      surname: "",
      phone: "",
      country: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
    };
  },
  mounted() {
    this.getCounties();
    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignIn();
        }
      });
  },
  computed: {
    requiredFieldsAreFilled2() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.email &&
        this.phone &&
        this.country &&
        this.city &&
        this.address &&
        this.postCode
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.email &&
        this.phone &&
        this.country &&
        this.city &&
        this.address &&
        this.postCode &&
        this.terms &&
        this.card_holder_name &&
        this.card_number &&
        this.expire_month &&
        this.expire_year
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    closeAll() {
      this.$parent.closeAcceptOfferModal();
      this.$parent.closeOfferDetailsModal();
    },
    confirm() {
      let data = {
        id: this.$parent.orderToView.id,
        card_number: this.card_number,
        card_holder_name: this.card_holder_name,
        expire_month: this.expire_month,
        expire_year: this.expire_year,
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phone,
        country: this.country.title,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
        currency: this.$parent.currency,
      };
      this.$http
        .post(process.env.VUE_APP_API + "offer/withdraw/card", data)
        .then(() => {
          this.$parent.closeAcceptOfferModal();
          this.$parent.closeOfferDetailsModal();
          this.$router.push({ path: "/offer-accepted-succes" });
        })
        .catch((res) => {
          if (res.response.data.errors) {
            const firstErrors = {};
            for (const key in res.response.data.errors) {
              if (
                res.response.data.errors.hasOwnProperty(key) &&
                Array.isArray(res.response.data.errors[key]) &&
                res.response.data.errors[key].length > 0
              ) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.$emit("setError", firstErrors[key]);
              }
            }
          } else {
            this.$emit("setError", res.response.data.message);
          }
        });
    },
    goToTerms() {
      this.closeAll();
      this.$parent.goToPage("terms");
    },
    goToPrivacy() {
      this.closeAll();
      this.$parent.goToPage("privacy");
    },
    getCounties() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          this.countryOptions = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
  },
};
</script>
