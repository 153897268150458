import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "./pages/PageNotFound.vue";
import Home from "./pages/Home.vue";
import ProductListPage from "./pages/ProductListPage.vue";
import NewArrivals from "./pages/NewArrivals.vue";
import Sale from "./pages/Sale.vue";
import Recommend from "./pages/Recommend.vue";
import Profile from "./pages/Profile.vue";
import SellOffers from "./pages/SellOffers.vue";
import ProductPage from "./pages/ProductPage.vue";
import PaymentSuccessPage from "./pages/PaymentSuccessPage.vue";
import PaymentFaliPage from "./pages/PaymentFaliPage.vue";
import TextPage from "./pages/TextPage.vue";
import OfferSuccessPage from "./pages/OfferSuccessPage.vue";
import OfferAcceptedPage from "./pages/OfferAcceptedPage.vue";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  routes: [
    //{ path: '/', redirect: '/home' },
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/product-list/:id",
      name: "ProductListPage",
      component: ProductListPage,
    },
    {
      path: "/fresh",
      name: "NewArrivals",
      component: NewArrivals,
    },
    {
      path: "/sale",
      name: "Sale",
      component: Sale,
    },
    {
      path: "/recommend",
      name: "Recommend",
      component: Recommend,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/orders",
      name: "SellOffers",
      component: SellOffers,
    },
    {
      path: "/products/:id",
      name: "ProductPage",
      component: ProductPage,
    },
    {
      path: "/success-payment",
      name: "PaymentSuccessPage",
      component: PaymentSuccessPage,
    },
    {
      path: "/fail-payment",
      name: "PaymentFaliPage",
      component: PaymentFaliPage,
    },
    {
      path: "/pages/:id",
      name: "TextPage",
      component: TextPage,
    },
    {
      path: "/offer-sent-succes",
      name: "OfferSuccessPage",
      component: OfferSuccessPage,
    },
    {
      path: "/offer-accepted-succes",
      name: "OfferAcceptedPage",
      component: OfferAcceptedPage,
    },
    {
      path: "*",
      name: "PageNotFound",
      component: PageNotFound,
      props: { title: "PageNotFound" },
    },
  ],
});
